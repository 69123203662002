import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FineArt from "../components/FineArt/FineArt"

const FineArtPage = () => (
  <Layout>
    <Seo title="Fine Art" />
    <FineArt />
  </Layout>
)

export default FineArtPage
