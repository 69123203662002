import React, { Component } from 'react';
import * as styles from './fine_art.module.scss';


export default class FineArt extends Component {
  render() {
    return (
      <section className={styles.fineArtSection}>
        <h1>Fine Art Service</h1>
        <p>
          An individual approach is the key principle of Zaru’s work. That is why many of the photos
          can be color adjusted to meet your goals. If you prefer to see certain photos in different
          shades or hues, simply ask and you can work with Zaru to determine the best way to meet
          your design ambitions. If you are looking to extend the series of the photo you choose,
          additional or custom sets are available. All of the images are offered as limited editions,
          and all the fine art prints are titled, numbered and signed on the front by Yulia Zaru.
        </p>
        <p>
          Most of the pictures can be produced in any size on any material. Your imagination is the
          only limit. Zaru partners with one of the best printing boutiques in the country that uses
          top quality American-made materials.
        </p>
        <p>
          Questions regarding pricing will be answered promptly if you send a message with a brief
          description of your project, image title and the print size you have in mind.
        </p>

        <a href="mailto:hi@yuliazaru.com?Subject=Consulting">Send your message</a>
      </section>
    );
  }
}
